/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type CreateChannelInput = {
  id?: string | null;
  name: string;
  language?: string | null;
  copyright?: string | null;
  description?: string | null;
  ownerName?: string | null;
  ownerEmail?: string | null;
  category?: string | null;
  explicit: boolean;
};

export type ModelChannelConditionInput = {
  name?: ModelStringInput | null;
  language?: ModelStringInput | null;
  copyright?: ModelStringInput | null;
  description?: ModelStringInput | null;
  ownerName?: ModelStringInput | null;
  ownerEmail?: ModelStringInput | null;
  category?: ModelStringInput | null;
  explicit?: ModelBooleanInput | null;
  and?: Array<ModelChannelConditionInput | null> | null;
  or?: Array<ModelChannelConditionInput | null> | null;
  not?: ModelChannelConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateChannelInput = {
  id: string;
  name?: string | null;
  language?: string | null;
  copyright?: string | null;
  description?: string | null;
  ownerName?: string | null;
  ownerEmail?: string | null;
  category?: string | null;
  explicit?: boolean | null;
};

export type DeleteChannelInput = {
  id?: string | null;
};

export type CreateEpisodeInput = {
  id?: string | null;
  title: string;
  description: string;
  channelID: string;
  season?: number | null;
  episode_number?: number | null;
  duration?: number | null;
  downloadLink?: string | null;
  guid?: string | null;
  explicit: boolean;
  createdOn?: string | null;
};

export type ModelEpisodeConditionInput = {
  title?: ModelStringInput | null;
  description?: ModelStringInput | null;
  channelID?: ModelIDInput | null;
  season?: ModelIntInput | null;
  episode_number?: ModelIntInput | null;
  duration?: ModelIntInput | null;
  downloadLink?: ModelStringInput | null;
  guid?: ModelStringInput | null;
  explicit?: ModelBooleanInput | null;
  createdOn?: ModelStringInput | null;
  and?: Array<ModelEpisodeConditionInput | null> | null;
  or?: Array<ModelEpisodeConditionInput | null> | null;
  not?: ModelEpisodeConditionInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateEpisodeInput = {
  id: string;
  title?: string | null;
  description?: string | null;
  channelID?: string | null;
  season?: number | null;
  episode_number?: number | null;
  duration?: number | null;
  downloadLink?: string | null;
  guid?: string | null;
  explicit?: boolean | null;
  createdOn?: string | null;
};

export type DeleteEpisodeInput = {
  id?: string | null;
};

export type ModelChannelFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  language?: ModelStringInput | null;
  copyright?: ModelStringInput | null;
  description?: ModelStringInput | null;
  ownerName?: ModelStringInput | null;
  ownerEmail?: ModelStringInput | null;
  category?: ModelStringInput | null;
  explicit?: ModelBooleanInput | null;
  and?: Array<ModelChannelFilterInput | null> | null;
  or?: Array<ModelChannelFilterInput | null> | null;
  not?: ModelChannelFilterInput | null;
};

export type ModelEpisodeFilterInput = {
  id?: ModelIDInput | null;
  title?: ModelStringInput | null;
  description?: ModelStringInput | null;
  channelID?: ModelIDInput | null;
  season?: ModelIntInput | null;
  episode_number?: ModelIntInput | null;
  duration?: ModelIntInput | null;
  downloadLink?: ModelStringInput | null;
  guid?: ModelStringInput | null;
  explicit?: ModelBooleanInput | null;
  createdOn?: ModelStringInput | null;
  and?: Array<ModelEpisodeFilterInput | null> | null;
  or?: Array<ModelEpisodeFilterInput | null> | null;
  not?: ModelEpisodeFilterInput | null;
};

export type CreateChannelMutation = {
  __typename: "Channel";
  id: string;
  name: string;
  language: string | null;
  copyright: string | null;
  description: string | null;
  ownerName: string | null;
  ownerEmail: string | null;
  category: string | null;
  explicit: boolean;
  episodes: {
    __typename: "ModelEpisodeConnection";
    items: Array<{
      __typename: "Episode";
      id: string;
      title: string;
      description: string;
      channelID: string;
      season: number | null;
      episode_number: number | null;
      duration: number | null;
      downloadLink: string | null;
      guid: string | null;
      explicit: boolean;
      createdOn: string;
      channel: {
        __typename: "Channel";
        id: string;
        name: string;
        language: string | null;
        copyright: string | null;
        description: string | null;
        ownerName: string | null;
        ownerEmail: string | null;
        category: string | null;
        explicit: boolean;
        createdOn: string;
        updatedOn: string;
        owner: string | null;
      } | null;
      updatedOn: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdOn: string;
  updatedOn: string;
  owner: string | null;
};

export type UpdateChannelMutation = {
  __typename: "Channel";
  id: string;
  name: string;
  language: string | null;
  copyright: string | null;
  description: string | null;
  ownerName: string | null;
  ownerEmail: string | null;
  category: string | null;
  explicit: boolean;
  episodes: {
    __typename: "ModelEpisodeConnection";
    items: Array<{
      __typename: "Episode";
      id: string;
      title: string;
      description: string;
      channelID: string;
      season: number | null;
      episode_number: number | null;
      duration: number | null;
      downloadLink: string | null;
      guid: string | null;
      explicit: boolean;
      createdOn: string;
      channel: {
        __typename: "Channel";
        id: string;
        name: string;
        language: string | null;
        copyright: string | null;
        description: string | null;
        ownerName: string | null;
        ownerEmail: string | null;
        category: string | null;
        explicit: boolean;
        createdOn: string;
        updatedOn: string;
        owner: string | null;
      } | null;
      updatedOn: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdOn: string;
  updatedOn: string;
  owner: string | null;
};

export type DeleteChannelMutation = {
  __typename: "Channel";
  id: string;
  name: string;
  language: string | null;
  copyright: string | null;
  description: string | null;
  ownerName: string | null;
  ownerEmail: string | null;
  category: string | null;
  explicit: boolean;
  episodes: {
    __typename: "ModelEpisodeConnection";
    items: Array<{
      __typename: "Episode";
      id: string;
      title: string;
      description: string;
      channelID: string;
      season: number | null;
      episode_number: number | null;
      duration: number | null;
      downloadLink: string | null;
      guid: string | null;
      explicit: boolean;
      createdOn: string;
      channel: {
        __typename: "Channel";
        id: string;
        name: string;
        language: string | null;
        copyright: string | null;
        description: string | null;
        ownerName: string | null;
        ownerEmail: string | null;
        category: string | null;
        explicit: boolean;
        createdOn: string;
        updatedOn: string;
        owner: string | null;
      } | null;
      updatedOn: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdOn: string;
  updatedOn: string;
  owner: string | null;
};

export type CreateEpisodeMutation = {
  __typename: "Episode";
  id: string;
  title: string;
  description: string;
  channelID: string;
  season: number | null;
  episode_number: number | null;
  duration: number | null;
  downloadLink: string | null;
  guid: string | null;
  explicit: boolean;
  createdOn: string;
  channel: {
    __typename: "Channel";
    id: string;
    name: string;
    language: string | null;
    copyright: string | null;
    description: string | null;
    ownerName: string | null;
    ownerEmail: string | null;
    category: string | null;
    explicit: boolean;
    episodes: {
      __typename: "ModelEpisodeConnection";
      items: Array<{
        __typename: "Episode";
        id: string;
        title: string;
        description: string;
        channelID: string;
        season: number | null;
        episode_number: number | null;
        duration: number | null;
        downloadLink: string | null;
        guid: string | null;
        explicit: boolean;
        createdOn: string;
        updatedOn: string;
        owner: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdOn: string;
    updatedOn: string;
    owner: string | null;
  } | null;
  updatedOn: string;
  owner: string | null;
};

export type UpdateEpisodeMutation = {
  __typename: "Episode";
  id: string;
  title: string;
  description: string;
  channelID: string;
  season: number | null;
  episode_number: number | null;
  duration: number | null;
  downloadLink: string | null;
  guid: string | null;
  explicit: boolean;
  createdOn: string;
  channel: {
    __typename: "Channel";
    id: string;
    name: string;
    language: string | null;
    copyright: string | null;
    description: string | null;
    ownerName: string | null;
    ownerEmail: string | null;
    category: string | null;
    explicit: boolean;
    episodes: {
      __typename: "ModelEpisodeConnection";
      items: Array<{
        __typename: "Episode";
        id: string;
        title: string;
        description: string;
        channelID: string;
        season: number | null;
        episode_number: number | null;
        duration: number | null;
        downloadLink: string | null;
        guid: string | null;
        explicit: boolean;
        createdOn: string;
        updatedOn: string;
        owner: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdOn: string;
    updatedOn: string;
    owner: string | null;
  } | null;
  updatedOn: string;
  owner: string | null;
};

export type DeleteEpisodeMutation = {
  __typename: "Episode";
  id: string;
  title: string;
  description: string;
  channelID: string;
  season: number | null;
  episode_number: number | null;
  duration: number | null;
  downloadLink: string | null;
  guid: string | null;
  explicit: boolean;
  createdOn: string;
  channel: {
    __typename: "Channel";
    id: string;
    name: string;
    language: string | null;
    copyright: string | null;
    description: string | null;
    ownerName: string | null;
    ownerEmail: string | null;
    category: string | null;
    explicit: boolean;
    episodes: {
      __typename: "ModelEpisodeConnection";
      items: Array<{
        __typename: "Episode";
        id: string;
        title: string;
        description: string;
        channelID: string;
        season: number | null;
        episode_number: number | null;
        duration: number | null;
        downloadLink: string | null;
        guid: string | null;
        explicit: boolean;
        createdOn: string;
        updatedOn: string;
        owner: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdOn: string;
    updatedOn: string;
    owner: string | null;
  } | null;
  updatedOn: string;
  owner: string | null;
};

export type GetChannelQuery = {
  __typename: "Channel";
  id: string;
  name: string;
  language: string | null;
  copyright: string | null;
  description: string | null;
  ownerName: string | null;
  ownerEmail: string | null;
  category: string | null;
  explicit: boolean;
  episodes: {
    __typename: "ModelEpisodeConnection";
    items: Array<{
      __typename: "Episode";
      id: string;
      title: string;
      description: string;
      channelID: string;
      season: number | null;
      episode_number: number | null;
      duration: number | null;
      downloadLink: string | null;
      guid: string | null;
      explicit: boolean;
      createdOn: string;
      channel: {
        __typename: "Channel";
        id: string;
        name: string;
        language: string | null;
        copyright: string | null;
        description: string | null;
        ownerName: string | null;
        ownerEmail: string | null;
        category: string | null;
        explicit: boolean;
        createdOn: string;
        updatedOn: string;
        owner: string | null;
      } | null;
      updatedOn: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdOn: string;
  updatedOn: string;
  owner: string | null;
};

export type ListChannelsQuery = {
  __typename: "ModelChannelConnection";
  items: Array<{
    __typename: "Channel";
    id: string;
    name: string;
    language: string | null;
    copyright: string | null;
    description: string | null;
    ownerName: string | null;
    ownerEmail: string | null;
    category: string | null;
    explicit: boolean;
    episodes: {
      __typename: "ModelEpisodeConnection";
      items: Array<{
        __typename: "Episode";
        id: string;
        title: string;
        description: string;
        channelID: string;
        season: number | null;
        episode_number: number | null;
        duration: number | null;
        downloadLink: string | null;
        guid: string | null;
        explicit: boolean;
        createdOn: string;
        updatedOn: string;
        owner: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdOn: string;
    updatedOn: string;
    owner: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetEpisodeQuery = {
  __typename: "Episode";
  id: string;
  title: string;
  description: string;
  channelID: string;
  season: number | null;
  episode_number: number | null;
  duration: number | null;
  downloadLink: string | null;
  guid: string | null;
  explicit: boolean;
  createdOn: string;
  channel: {
    __typename: "Channel";
    id: string;
    name: string;
    language: string | null;
    copyright: string | null;
    description: string | null;
    ownerName: string | null;
    ownerEmail: string | null;
    category: string | null;
    explicit: boolean;
    episodes: {
      __typename: "ModelEpisodeConnection";
      items: Array<{
        __typename: "Episode";
        id: string;
        title: string;
        description: string;
        channelID: string;
        season: number | null;
        episode_number: number | null;
        duration: number | null;
        downloadLink: string | null;
        guid: string | null;
        explicit: boolean;
        createdOn: string;
        updatedOn: string;
        owner: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdOn: string;
    updatedOn: string;
    owner: string | null;
  } | null;
  updatedOn: string;
  owner: string | null;
};

export type ListEpisodesQuery = {
  __typename: "ModelEpisodeConnection";
  items: Array<{
    __typename: "Episode";
    id: string;
    title: string;
    description: string;
    channelID: string;
    season: number | null;
    episode_number: number | null;
    duration: number | null;
    downloadLink: string | null;
    guid: string | null;
    explicit: boolean;
    createdOn: string;
    channel: {
      __typename: "Channel";
      id: string;
      name: string;
      language: string | null;
      copyright: string | null;
      description: string | null;
      ownerName: string | null;
      ownerEmail: string | null;
      category: string | null;
      explicit: boolean;
      episodes: {
        __typename: "ModelEpisodeConnection";
        nextToken: string | null;
      } | null;
      createdOn: string;
      updatedOn: string;
      owner: string | null;
    } | null;
    updatedOn: string;
    owner: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type OnCreateChannelSubscription = {
  __typename: "Channel";
  id: string;
  name: string;
  language: string | null;
  copyright: string | null;
  description: string | null;
  ownerName: string | null;
  ownerEmail: string | null;
  category: string | null;
  explicit: boolean;
  episodes: {
    __typename: "ModelEpisodeConnection";
    items: Array<{
      __typename: "Episode";
      id: string;
      title: string;
      description: string;
      channelID: string;
      season: number | null;
      episode_number: number | null;
      duration: number | null;
      downloadLink: string | null;
      guid: string | null;
      explicit: boolean;
      createdOn: string;
      channel: {
        __typename: "Channel";
        id: string;
        name: string;
        language: string | null;
        copyright: string | null;
        description: string | null;
        ownerName: string | null;
        ownerEmail: string | null;
        category: string | null;
        explicit: boolean;
        createdOn: string;
        updatedOn: string;
        owner: string | null;
      } | null;
      updatedOn: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdOn: string;
  updatedOn: string;
  owner: string | null;
};

export type OnUpdateChannelSubscription = {
  __typename: "Channel";
  id: string;
  name: string;
  language: string | null;
  copyright: string | null;
  description: string | null;
  ownerName: string | null;
  ownerEmail: string | null;
  category: string | null;
  explicit: boolean;
  episodes: {
    __typename: "ModelEpisodeConnection";
    items: Array<{
      __typename: "Episode";
      id: string;
      title: string;
      description: string;
      channelID: string;
      season: number | null;
      episode_number: number | null;
      duration: number | null;
      downloadLink: string | null;
      guid: string | null;
      explicit: boolean;
      createdOn: string;
      channel: {
        __typename: "Channel";
        id: string;
        name: string;
        language: string | null;
        copyright: string | null;
        description: string | null;
        ownerName: string | null;
        ownerEmail: string | null;
        category: string | null;
        explicit: boolean;
        createdOn: string;
        updatedOn: string;
        owner: string | null;
      } | null;
      updatedOn: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdOn: string;
  updatedOn: string;
  owner: string | null;
};

export type OnDeleteChannelSubscription = {
  __typename: "Channel";
  id: string;
  name: string;
  language: string | null;
  copyright: string | null;
  description: string | null;
  ownerName: string | null;
  ownerEmail: string | null;
  category: string | null;
  explicit: boolean;
  episodes: {
    __typename: "ModelEpisodeConnection";
    items: Array<{
      __typename: "Episode";
      id: string;
      title: string;
      description: string;
      channelID: string;
      season: number | null;
      episode_number: number | null;
      duration: number | null;
      downloadLink: string | null;
      guid: string | null;
      explicit: boolean;
      createdOn: string;
      channel: {
        __typename: "Channel";
        id: string;
        name: string;
        language: string | null;
        copyright: string | null;
        description: string | null;
        ownerName: string | null;
        ownerEmail: string | null;
        category: string | null;
        explicit: boolean;
        createdOn: string;
        updatedOn: string;
        owner: string | null;
      } | null;
      updatedOn: string;
      owner: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  createdOn: string;
  updatedOn: string;
  owner: string | null;
};

export type OnCreateEpisodeSubscription = {
  __typename: "Episode";
  id: string;
  title: string;
  description: string;
  channelID: string;
  season: number | null;
  episode_number: number | null;
  duration: number | null;
  downloadLink: string | null;
  guid: string | null;
  explicit: boolean;
  createdOn: string;
  channel: {
    __typename: "Channel";
    id: string;
    name: string;
    language: string | null;
    copyright: string | null;
    description: string | null;
    ownerName: string | null;
    ownerEmail: string | null;
    category: string | null;
    explicit: boolean;
    episodes: {
      __typename: "ModelEpisodeConnection";
      items: Array<{
        __typename: "Episode";
        id: string;
        title: string;
        description: string;
        channelID: string;
        season: number | null;
        episode_number: number | null;
        duration: number | null;
        downloadLink: string | null;
        guid: string | null;
        explicit: boolean;
        createdOn: string;
        updatedOn: string;
        owner: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdOn: string;
    updatedOn: string;
    owner: string | null;
  } | null;
  updatedOn: string;
  owner: string | null;
};

export type OnUpdateEpisodeSubscription = {
  __typename: "Episode";
  id: string;
  title: string;
  description: string;
  channelID: string;
  season: number | null;
  episode_number: number | null;
  duration: number | null;
  downloadLink: string | null;
  guid: string | null;
  explicit: boolean;
  createdOn: string;
  channel: {
    __typename: "Channel";
    id: string;
    name: string;
    language: string | null;
    copyright: string | null;
    description: string | null;
    ownerName: string | null;
    ownerEmail: string | null;
    category: string | null;
    explicit: boolean;
    episodes: {
      __typename: "ModelEpisodeConnection";
      items: Array<{
        __typename: "Episode";
        id: string;
        title: string;
        description: string;
        channelID: string;
        season: number | null;
        episode_number: number | null;
        duration: number | null;
        downloadLink: string | null;
        guid: string | null;
        explicit: boolean;
        createdOn: string;
        updatedOn: string;
        owner: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdOn: string;
    updatedOn: string;
    owner: string | null;
  } | null;
  updatedOn: string;
  owner: string | null;
};

export type OnDeleteEpisodeSubscription = {
  __typename: "Episode";
  id: string;
  title: string;
  description: string;
  channelID: string;
  season: number | null;
  episode_number: number | null;
  duration: number | null;
  downloadLink: string | null;
  guid: string | null;
  explicit: boolean;
  createdOn: string;
  channel: {
    __typename: "Channel";
    id: string;
    name: string;
    language: string | null;
    copyright: string | null;
    description: string | null;
    ownerName: string | null;
    ownerEmail: string | null;
    category: string | null;
    explicit: boolean;
    episodes: {
      __typename: "ModelEpisodeConnection";
      items: Array<{
        __typename: "Episode";
        id: string;
        title: string;
        description: string;
        channelID: string;
        season: number | null;
        episode_number: number | null;
        duration: number | null;
        downloadLink: string | null;
        guid: string | null;
        explicit: boolean;
        createdOn: string;
        updatedOn: string;
        owner: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    createdOn: string;
    updatedOn: string;
    owner: string | null;
  } | null;
  updatedOn: string;
  owner: string | null;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateChannel(
    input: CreateChannelInput,
    condition?: ModelChannelConditionInput
  ): Promise<CreateChannelMutation> {
    const statement = `mutation CreateChannel($input: CreateChannelInput!, $condition: ModelChannelConditionInput) {
        createChannel(input: $input, condition: $condition) {
          __typename
          id
          name
          language
          copyright
          description
          ownerName
          ownerEmail
          category
          explicit
          episodes {
            __typename
            items {
              __typename
              id
              title
              description
              channelID
              season
              episode_number
              duration
              downloadLink
              guid
              explicit
              createdOn
              channel {
                __typename
                id
                name
                language
                copyright
                description
                ownerName
                ownerEmail
                category
                explicit
                createdOn
                updatedOn
                owner
              }
              updatedOn
              owner
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateChannelMutation>response.data.createChannel;
  }
  async UpdateChannel(
    input: UpdateChannelInput,
    condition?: ModelChannelConditionInput
  ): Promise<UpdateChannelMutation> {
    const statement = `mutation UpdateChannel($input: UpdateChannelInput!, $condition: ModelChannelConditionInput) {
        updateChannel(input: $input, condition: $condition) {
          __typename
          id
          name
          language
          copyright
          description
          ownerName
          ownerEmail
          category
          explicit
          episodes {
            __typename
            items {
              __typename
              id
              title
              description
              channelID
              season
              episode_number
              duration
              downloadLink
              guid
              explicit
              createdOn
              channel {
                __typename
                id
                name
                language
                copyright
                description
                ownerName
                ownerEmail
                category
                explicit
                createdOn
                updatedOn
                owner
              }
              updatedOn
              owner
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateChannelMutation>response.data.updateChannel;
  }
  async DeleteChannel(
    input: DeleteChannelInput,
    condition?: ModelChannelConditionInput
  ): Promise<DeleteChannelMutation> {
    const statement = `mutation DeleteChannel($input: DeleteChannelInput!, $condition: ModelChannelConditionInput) {
        deleteChannel(input: $input, condition: $condition) {
          __typename
          id
          name
          language
          copyright
          description
          ownerName
          ownerEmail
          category
          explicit
          episodes {
            __typename
            items {
              __typename
              id
              title
              description
              channelID
              season
              episode_number
              duration
              downloadLink
              guid
              explicit
              createdOn
              channel {
                __typename
                id
                name
                language
                copyright
                description
                ownerName
                ownerEmail
                category
                explicit
                createdOn
                updatedOn
                owner
              }
              updatedOn
              owner
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteChannelMutation>response.data.deleteChannel;
  }
  async CreateEpisode(
    input: CreateEpisodeInput,
    condition?: ModelEpisodeConditionInput
  ): Promise<CreateEpisodeMutation> {
    const statement = `mutation CreateEpisode($input: CreateEpisodeInput!, $condition: ModelEpisodeConditionInput) {
        createEpisode(input: $input, condition: $condition) {
          __typename
          id
          title
          description
          channelID
          season
          episode_number
          duration
          downloadLink
          guid
          explicit
          createdOn
          channel {
            __typename
            id
            name
            language
            copyright
            description
            ownerName
            ownerEmail
            category
            explicit
            episodes {
              __typename
              items {
                __typename
                id
                title
                description
                channelID
                season
                episode_number
                duration
                downloadLink
                guid
                explicit
                createdOn
                updatedOn
                owner
              }
              nextToken
            }
            createdOn
            updatedOn
            owner
          }
          updatedOn
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEpisodeMutation>response.data.createEpisode;
  }
  async UpdateEpisode(
    input: UpdateEpisodeInput,
    condition?: ModelEpisodeConditionInput
  ): Promise<UpdateEpisodeMutation> {
    const statement = `mutation UpdateEpisode($input: UpdateEpisodeInput!, $condition: ModelEpisodeConditionInput) {
        updateEpisode(input: $input, condition: $condition) {
          __typename
          id
          title
          description
          channelID
          season
          episode_number
          duration
          downloadLink
          guid
          explicit
          createdOn
          channel {
            __typename
            id
            name
            language
            copyright
            description
            ownerName
            ownerEmail
            category
            explicit
            episodes {
              __typename
              items {
                __typename
                id
                title
                description
                channelID
                season
                episode_number
                duration
                downloadLink
                guid
                explicit
                createdOn
                updatedOn
                owner
              }
              nextToken
            }
            createdOn
            updatedOn
            owner
          }
          updatedOn
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEpisodeMutation>response.data.updateEpisode;
  }
  async DeleteEpisode(
    input: DeleteEpisodeInput,
    condition?: ModelEpisodeConditionInput
  ): Promise<DeleteEpisodeMutation> {
    const statement = `mutation DeleteEpisode($input: DeleteEpisodeInput!, $condition: ModelEpisodeConditionInput) {
        deleteEpisode(input: $input, condition: $condition) {
          __typename
          id
          title
          description
          channelID
          season
          episode_number
          duration
          downloadLink
          guid
          explicit
          createdOn
          channel {
            __typename
            id
            name
            language
            copyright
            description
            ownerName
            ownerEmail
            category
            explicit
            episodes {
              __typename
              items {
                __typename
                id
                title
                description
                channelID
                season
                episode_number
                duration
                downloadLink
                guid
                explicit
                createdOn
                updatedOn
                owner
              }
              nextToken
            }
            createdOn
            updatedOn
            owner
          }
          updatedOn
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEpisodeMutation>response.data.deleteEpisode;
  }
  async GetChannel(id: string): Promise<GetChannelQuery> {
    const statement = `query GetChannel($id: ID!) {
        getChannel(id: $id) {
          __typename
          id
          name
          language
          copyright
          description
          ownerName
          ownerEmail
          category
          explicit
          episodes {
            __typename
            items {
              __typename
              id
              title
              description
              channelID
              season
              episode_number
              duration
              downloadLink
              guid
              explicit
              createdOn
              channel {
                __typename
                id
                name
                language
                copyright
                description
                ownerName
                ownerEmail
                category
                explicit
                createdOn
                updatedOn
                owner
              }
              updatedOn
              owner
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetChannelQuery>response.data.getChannel;
  }
  async ListChannels(
    filter?: ModelChannelFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListChannelsQuery> {
    const statement = `query ListChannels($filter: ModelChannelFilterInput, $limit: Int, $nextToken: String) {
        listChannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            language
            copyright
            description
            ownerName
            ownerEmail
            category
            explicit
            episodes {
              __typename
              items {
                __typename
                id
                title
                description
                channelID
                season
                episode_number
                duration
                downloadLink
                guid
                explicit
                createdOn
                updatedOn
                owner
              }
              nextToken
            }
            createdOn
            updatedOn
            owner
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListChannelsQuery>response.data.listChannels;
  }
  async GetEpisode(id: string): Promise<GetEpisodeQuery> {
    const statement = `query GetEpisode($id: ID!) {
        getEpisode(id: $id) {
          __typename
          id
          title
          description
          channelID
          season
          episode_number
          duration
          downloadLink
          guid
          explicit
          createdOn
          channel {
            __typename
            id
            name
            language
            copyright
            description
            ownerName
            ownerEmail
            category
            explicit
            episodes {
              __typename
              items {
                __typename
                id
                title
                description
                channelID
                season
                episode_number
                duration
                downloadLink
                guid
                explicit
                createdOn
                updatedOn
                owner
              }
              nextToken
            }
            createdOn
            updatedOn
            owner
          }
          updatedOn
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEpisodeQuery>response.data.getEpisode;
  }
  async ListEpisodes(
    filter?: ModelEpisodeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEpisodesQuery> {
    const statement = `query ListEpisodes($filter: ModelEpisodeFilterInput, $limit: Int, $nextToken: String) {
        listEpisodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            title
            description
            channelID
            season
            episode_number
            duration
            downloadLink
            guid
            explicit
            createdOn
            channel {
              __typename
              id
              name
              language
              copyright
              description
              ownerName
              ownerEmail
              category
              explicit
              episodes {
                __typename
                nextToken
              }
              createdOn
              updatedOn
              owner
            }
            updatedOn
            owner
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEpisodesQuery>response.data.listEpisodes;
  }
  OnCreateChannelListener: Observable<
    SubscriptionResponse<OnCreateChannelSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateChannel($owner: String!) {
        onCreateChannel(owner: $owner) {
          __typename
          id
          name
          language
          copyright
          description
          ownerName
          ownerEmail
          category
          explicit
          episodes {
            __typename
            items {
              __typename
              id
              title
              description
              channelID
              season
              episode_number
              duration
              downloadLink
              guid
              explicit
              createdOn
              channel {
                __typename
                id
                name
                language
                copyright
                description
                ownerName
                ownerEmail
                category
                explicit
                createdOn
                updatedOn
                owner
              }
              updatedOn
              owner
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateChannelSubscription>>;

  OnUpdateChannelListener: Observable<
    SubscriptionResponse<OnUpdateChannelSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateChannel($owner: String!) {
        onUpdateChannel(owner: $owner) {
          __typename
          id
          name
          language
          copyright
          description
          ownerName
          ownerEmail
          category
          explicit
          episodes {
            __typename
            items {
              __typename
              id
              title
              description
              channelID
              season
              episode_number
              duration
              downloadLink
              guid
              explicit
              createdOn
              channel {
                __typename
                id
                name
                language
                copyright
                description
                ownerName
                ownerEmail
                category
                explicit
                createdOn
                updatedOn
                owner
              }
              updatedOn
              owner
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateChannelSubscription>>;

  OnDeleteChannelListener: Observable<
    SubscriptionResponse<OnDeleteChannelSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteChannel($owner: String!) {
        onDeleteChannel(owner: $owner) {
          __typename
          id
          name
          language
          copyright
          description
          ownerName
          ownerEmail
          category
          explicit
          episodes {
            __typename
            items {
              __typename
              id
              title
              description
              channelID
              season
              episode_number
              duration
              downloadLink
              guid
              explicit
              createdOn
              channel {
                __typename
                id
                name
                language
                copyright
                description
                ownerName
                ownerEmail
                category
                explicit
                createdOn
                updatedOn
                owner
              }
              updatedOn
              owner
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteChannelSubscription>>;

  OnCreateEpisodeListener: Observable<
    SubscriptionResponse<OnCreateEpisodeSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateEpisode($owner: String!) {
        onCreateEpisode(owner: $owner) {
          __typename
          id
          title
          description
          channelID
          season
          episode_number
          duration
          downloadLink
          guid
          explicit
          createdOn
          channel {
            __typename
            id
            name
            language
            copyright
            description
            ownerName
            ownerEmail
            category
            explicit
            episodes {
              __typename
              items {
                __typename
                id
                title
                description
                channelID
                season
                episode_number
                duration
                downloadLink
                guid
                explicit
                createdOn
                updatedOn
                owner
              }
              nextToken
            }
            createdOn
            updatedOn
            owner
          }
          updatedOn
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateEpisodeSubscription>>;

  OnUpdateEpisodeListener: Observable<
    SubscriptionResponse<OnUpdateEpisodeSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateEpisode($owner: String!) {
        onUpdateEpisode(owner: $owner) {
          __typename
          id
          title
          description
          channelID
          season
          episode_number
          duration
          downloadLink
          guid
          explicit
          createdOn
          channel {
            __typename
            id
            name
            language
            copyright
            description
            ownerName
            ownerEmail
            category
            explicit
            episodes {
              __typename
              items {
                __typename
                id
                title
                description
                channelID
                season
                episode_number
                duration
                downloadLink
                guid
                explicit
                createdOn
                updatedOn
                owner
              }
              nextToken
            }
            createdOn
            updatedOn
            owner
          }
          updatedOn
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateEpisodeSubscription>>;

  OnDeleteEpisodeListener: Observable<
    SubscriptionResponse<OnDeleteEpisodeSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteEpisode($owner: String!) {
        onDeleteEpisode(owner: $owner) {
          __typename
          id
          title
          description
          channelID
          season
          episode_number
          duration
          downloadLink
          guid
          explicit
          createdOn
          channel {
            __typename
            id
            name
            language
            copyright
            description
            ownerName
            ownerEmail
            category
            explicit
            episodes {
              __typename
              items {
                __typename
                id
                title
                description
                channelID
                season
                episode_number
                duration
                downloadLink
                guid
                explicit
                createdOn
                updatedOn
                owner
              }
              nextToken
            }
            createdOn
            updatedOn
            owner
          }
          updatedOn
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteEpisodeSubscription>>;
}
