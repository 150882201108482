import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {NascentEpisode} from '../model/nascentEpisode';
import {APIService, ListChannelsQuery} from '../API.service';
import {v4} from 'uuid';

@Component({
  selector: 'app-create-episode',
  templateUrl: './create-episode.component.html',
  styleUrls: ['./create-episode.component.scss']
})
export class CreateEpisodeComponent implements OnInit {

  @Input() channels: ListChannelsQuery;
  @Output() submitted = new EventEmitter<string>();
  createEpisodeForm;
  message = '';

  constructor(
    private api: APIService,
    private formBuilder: FormBuilder,
  ) {
    this.createEpisodeForm = this.formBuilder.group({
      title: '',
      season: 1,
      episodeNumber: 1,
      parentChannel: '',
      description: '',
      channelId: '',
      explicit: false,
    });
  }

  ngOnInit(): void {}


  onSubmit(newEpisode: NascentEpisode): void {

    this.api.CreateEpisode({
      channelID: newEpisode.channelId,
      description: newEpisode.description,
      episode_number: newEpisode.episodeNumber,
      season: newEpisode.season,
      guid: v4(),
      id: v4(),
      explicit: newEpisode.explicit,
      title: newEpisode.title,
      duration: 0,
      downloadLink: ''
    }).then(createResult => {
      this.message = createResult.id;
      this.createEpisodeForm.reset();
      this.submitted.emit(createResult.title);
    });

  }


}
