<form [formGroup]="createEpisodeForm" (ngSubmit)="onSubmit(createEpisodeForm.value)">
  <div>
    <label for="channelId">Channel</label>
    <select *ngIf="channels" id="channelId" name="channelId" formControlName="channelId">
      <option *ngFor="let channel of channels.items" [ngValue]="channel.id">
        {{channel.name}}
      </option>
    </select>
  </div>
  <div>
    <label for="title">Title</label>
    <input id="title" type="text" formControlName="title">
  </div>
  <div>
    <label for="season">Season</label>
    <input id="season" type="text" formControlName="season">
  </div>
  <div>
    <label for="episodeNumber">Episode_number</label>
    <input id="episodeNumber" type="text" formControlName="episodeNumber">
  </div>
  <div>
    <label for="description">Description</label>
    <input id="description" type="text" formControlName="description">
  </div>
  <div>
    <label for="explicit">Contains explicit content?</label>
    <input id="explicit" type="checkbox" formControlName="explicit">
  </div>

  <button class="button" type="submit">Create Episode</button>
</form>

<p>{{message}}</p>
