import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CreatePodcastComponent} from './create-podcast/create-podcast.component';
import {CreateEpisodeComponent} from './create-episode/create-episode.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {AboutComponent} from './about/about.component';
import {Amplify, Auth} from 'aws-amplify';
import {AmplifyUIAngularModule} from '@aws-amplify/ui-angular';
import awsconfig from '../aws-exports';
import { ChannelItemComponent } from './channel-item/channel-item.component';
import { EpisodeViewComponent } from './episode-view/episode-view.component'
Amplify.configure(awsconfig);

@NgModule({
  declarations: [
    AppComponent,
    CreatePodcastComponent,
    CreateEpisodeComponent,
    DashboardComponent,
    AboutComponent,
    ChannelItemComponent,
    EpisodeViewComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AmplifyUIAngularModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor() {

    // const isLocalhost = Boolean(
    //   window.location.hostname === "localhost" ||
    //   // [::1] is the IPv6 localhost address.
    //   window.location.hostname === "[::1]" ||
    //   // 127.0.0.1/8 is considered localhost for IPv4.
    //   window.location.hostname.match(
    //     /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    //   )
    // );
    //
    // // Assuming you have two redirect URIs, and the first is for localhost and second is for production
    // const [
    //   localRedirectSignIn,
    //   productionRedirectSignIn,
    // ] = awsconfig.oauth.redirectSignIn.split(",");
    //
    // const [
    //   localRedirectSignOut,
    //   productionRedirectSignOut,
    // ] = awsconfig.oauth.redirectSignOut.split(",");
    //
    // const updatedawsconfig = {
    //   ...awsconfig,
    //   oauth: {
    //     ...awsconfig.oauth,
    //     redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    //     redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
    //   }
    // }
    //
    // Amplify.configure(updatedawsconfig);
  }


}
