import {Component, OnInit} from '@angular/core';
import {APIService, ListChannelsQuery} from '../API.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  results: ListChannelsQuery;

  constructor(
    private graphqlApi: APIService) {
  }

  ngOnInit(): void {
    this.getChannelContent();
  }

  onNewEpisode($event: string) {
    this.getChannelContent();
  }

  getChannelContent(): void {
    this.graphqlApi.ListChannels().then(results => {
      this.results = results;
    });
  }

}
