<table>
  <tr [id]="channel.id">
    <td>
      <div class="image-container">
        <img [src]="channelImageUrl" alt="Image related ot the podcast {{channel.name}}">
        <label for="changeImage-{{channel.id}}">Change</label>
        <input id="changeImage-{{channel.id}}" type="file" class="image-button, changeImage" (change)="onFileChanged($event)"/>
      </div>
    </td>
    <td>
      <h2>{{channel.name}}</h2>
      <h3>{{channel.description}}</h3>
      <p>Episodes</p>
      <app-episode-view
        *ngFor="let episode of channel.episodes.items"
        [episode]="episode">
      </app-episode-view>
    </td>
    <td>
      <amplify-button>Edit</amplify-button>
      <amplify-button (click)="delete()">Delete</amplify-button>
    </td>
  </tr>

</table>
