
<div>
  <amplify-authenticator>

    <div>
      <nav>
        <p>
          <a routerLink="dashboard">Dashboard</a>
        </p>
        <p>
          <a routerLink="create-podcast">Create a Podcast</a>
        </p>
        <p>
          <a routerLink="about">About</a>
        </p>
      </nav>
    </div>
    <div style="width: 240px">
      Ponder Podcasts
      <amplify-sign-out ></amplify-sign-out>
    </div>
    <router-outlet></router-outlet>
  </amplify-authenticator>
</div>
