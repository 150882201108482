import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CreatePodcastComponent } from './create-podcast/create-podcast.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AboutComponent } from './about/about.component';


const routes: Routes = [
  { path: 'create-podcast', component: CreatePodcastComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'about', component: AboutComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
