<form [formGroup]="createPodcastForm" (ngSubmit)="onSubmit(createPodcastForm.value)">
  <div>
    <label for="title">Title</label>
    <input id="title" type="text" formControlName="title">
  </div>
  <div>
    <label for="language">Language</label>
    <input id="language" type="text" formControlName="language">
  </div>
  <div>
    <label for="copyright">Copyright</label>
    <input id="copyright" type="text" formControlName="copyright">
  </div>
  <div>
    <label for="author">Author</label>
    <input id="author" type="text" formControlName="author">
  </div>
  <div>
    <label for="description">Description</label>
    <input id="description" type="text" formControlName="description">
  </div>
  <div>
    <label for="ownerName">Owner Name</label>
    <input id="ownerName" type="text" formControlName="ownerName">
  </div>
  <div>
    <label for="ownerEmail">Owner Email</label>
    <input id="ownerEmail" type="text" formControlName="ownerEmail">
  </div>
  <div>
    <label for="image">Image</label>
    <input id="image" type="file" (change)="onFileChanged($event)">
  </div>
  <div>
    <label for="category">Category</label>
    <input id="category" type="text" formControlName="category">
  </div>
  <div>
    <label for="explicit">Contains explicit content?</label>
    <input id="explicit" type="checkbox" formControlName="explicit">
  </div>

  <button class="button" type="submit">Create Channel</button>
</form>

<p>{{message}}</p>
