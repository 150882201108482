import {Component, Input, OnInit} from '@angular/core';
import {SignedUrlResponse, UploadEpisodeService} from '../upload-episode.service';
import {APIService, GetEpisodeQuery} from '../API.service';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-episode-view',
  templateUrl: './episode-view.component.html',
  styleUrls: ['./episode-view.component.scss']
})
export class EpisodeViewComponent implements OnInit {
  @Input() episode: GetEpisodeQuery;
  fileToUpload: File = null;

  constructor(
    private uploadEpisodeService: UploadEpisodeService,
    private api: APIService
  ) {
  }

  ngOnInit(): void {
    console.log(JSON.stringify(this.episode));
  }

  async upload() {
    // await this.uploadEpisodeService.uploadEpisode(this.episode.id, null);

  }

  onFileChange(files: FileList) {
    this.fileToUpload = files.item(0);
    this.uploadEpisodeService.getSignedUploadUrl().subscribe(
      signedUrlResponse => {
        console.log(signedUrlResponse);
        this.uploadEpisodeService.uploadEpisode(signedUrlResponse, this.episode.id, this.fileToUpload).subscribe(
          value => {
            console.log(value)
            console.log("Successfully uploaded episode!")
            this.episode.downloadLink = signedUrlResponse.Key
            this.api.UpdateEpisode({
              id: this.episode.id,
              downloadLink: this.episode.downloadLink
            })
              .then(r => console.log(r))
          }
        )
      });
  }
}
