/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://swx3ozv4ljhwjgknrunenlxcxy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:f83f5018-1920-4069-9e84-fd44c8c7cf39",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_AeUm23CfK",
    "aws_user_pools_web_client_id": "66drnuskebu00vhj49dh75cgu0",
    "oauth": {
        "domain": "ponderpodportal4794680b-4794680b-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:4200/",
        "redirectSignOut": "http://localhost:4200/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "userstorage-bucket202800-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
