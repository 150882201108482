<!--// Total downloads of latest episodes-->
<!--// listener demographics-->

<div>

  <app-create-episode
    [channels]="results"
    (submitted)="onNewEpisode($event)">
  </app-create-episode>


  <p>Your Channel</p>
  <div *ngIf="results">
    <div *ngFor="let chan of results.items">
      <app-channel-item [channel]="chan" (deleted)="getChannelContent()"></app-channel-item>
    </div>
  </div>
</div>
