import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {Channel} from '../model/channel';
import {APIService} from '../API.service';
import {v4} from 'uuid';
import {Auth, Storage} from 'aws-amplify';

@Component({
  selector: 'app-create-podcast',
  templateUrl: './create-podcast.component.html',
  styleUrls: ['./create-podcast.component.scss']
})
export class CreatePodcastComponent implements OnInit {

  createPodcastForm;
  message = '';
  podcastId = v4();

  constructor(
    private api: APIService,
    private formBuilder: FormBuilder,
  ) {
    this.createPodcastForm = this.formBuilder.group({
      title: '',
      language: '',
      copyright: new Date().getFullYear(),
      author: '',
      description: '',
      ownerEmail: '',
      ownerName: '',
      category: '',
      explicit: false,
    });
    Auth.currentUserInfo().then( user => {

      let email = user.attributes.email;
      if (typeof email !== 'undefined') {
        this.createPodcastForm.patchValue({
          ownerEmail: email
        })
      }

      let name = user.attributes.name;
      if (typeof name !== 'undefined') {
        this.createPodcastForm.patchValue({
          author: name,
          ownerName: name
        })
      }

    })
  }

  ngOnInit(): void {
  }

  onFileChanged(event) {
    const file = event.target.files[0];
    Storage.put(this.podcastId + '-image.png', file, {
      contentType: 'image/png'
    })
      .then(result => {
        console.log('file upload complete');
        console.log(JSON.stringify(result));
      })
      .catch(err => console.log(err));
  }


  onSubmit(newChannel: Channel): void {

    this.api.CreateChannel({
      id: this.podcastId,
      name: newChannel.title,
      ownerEmail: newChannel.ownerEmail,
      ownerName: newChannel.author,
      description: newChannel.description,
      category: newChannel.category[0],
      copyright: newChannel.copyright,
      language: newChannel.language,
      explicit: false,
    }).then(result => this.message = 'Created new podcast: ' + result.name);
  }


}

export function requiredFileType(type: string) {
  return function(control: FormControl) {
    const file = control.value;
    if (file) {
      const extension = file.name.split('.')[1].toLowerCase();
      if (type.toLowerCase() !== extension.toLowerCase()) {
        return {
          requiredFileType: true
        };
      }

      return null;
    }

    return null;
  };
}
