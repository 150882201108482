import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {APIService, GetChannelQuery} from '../API.service';
import {DataStore, Storage} from 'aws-amplify';
import {Inject} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {UploadEpisodeService} from '../upload-episode.service';

@Component({
  selector: 'app-channel-item',
  templateUrl: './channel-item.component.html',
  styleUrls: ['./channel-item.component.scss']
})
export class ChannelItemComponent implements OnInit {

  @Input() channel: GetChannelQuery;
  @Output() deleted = new EventEmitter<string>();

  channelImageUrl: any;
  editing: boolean = false;

  constructor(
    private api: APIService,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  ngOnInit(): void {
    console.log(this.channel.id, this.channelImageUrl);

    console.log('getting file with key: ' + this.channel.id + "-image.png");
    Storage.get(this.channel.id + "-image.png")
      .then(result => {
          this.channelImageUrl = result
          console.log(JSON.stringify(result))
        }
      )
      .catch(error => {
          console.error(error);
        }
      )
  }

  edit(): void {
    this.editing = true;
  }

  save(): void {
    this.editing = false;
  }

  onFileChanged(event) {
    const file = event.target.files[0];
    Storage.put(this.channel.id + '-image.png', file, {
      contentType: 'image/png'
    })
      .then(result => {
        console.log('file upload complete');
        console.log(JSON.stringify(result));
        this.document.location.reload()
      })
      .catch(err => console.log(err));
  }

  delete(): void {
    console.log('Attempting to delete channel: ' + this.channel.name, this.channel.id);
    this.api.DeleteChannel({
        id: this.channel.id,
      })
    .then( result => {
      console.log(result);
      let x = document.getElementById(this.channel.id);
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
    })
      .catch(error => {
      console.log(error);
    })
  }

}
