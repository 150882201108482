import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {TempChannel} from './model/tempChannel';
import {APIService} from './API.service';


@Injectable({
  providedIn: 'root'
})
export class UploadEpisodeService {

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'audio/m4a'
    })
  }
  constructor(
    private http: HttpClient,
  ) {
  }

  getSignedUploadUrl(): Observable<SignedUrlResponse> {
    return this.http.get<SignedUrlResponse>('https://koxweyll27.execute-api.us-east-1.amazonaws.com/uploads');
  }

  uploadEpisode(destination: SignedUrlResponse, episodeId: string, file: File): Observable<any> {
    return this.http.put(destination.uploadURL, file, this.httpOptions)
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}

export class SignedUrlResponse {
  uploadURL: string;
  Key: string;
}
